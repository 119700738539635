import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});
/**
 * Global Config
 */

window.serverless = {};
window.serverless.urls = {};
if (window.location.host.includes("localhost:" || "cloudfront")) {
  window.serverless.urls.api = `https://api-dev.quizbrawl.com`;
} else {
  window.serverless.root = window.location.hostname.replace("www.", "");
  window.serverless.urls.api = `https://api.${window.serverless.root}`;
}

/**
 * Render App
 */

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
