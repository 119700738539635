import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styles from "./Quickplay.module.css";
import { getSession, deleteSession, getCategories } from "../../utils";
import Loading from "../../fragments/Loading";

class Quickplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true
    };

    // Bindings
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    try {
      const userSession = getSession();
      // Fetch categories
      const categories = await getCategories();
      // Update state with mission data
      await this.setState({
        categories: categories.categoryList,
        session: userSession,
        loading: false
      });
    } catch (error) {
      // Handle error
      console.log("There was an error when fetching categories");
      console.log(error);
    }
  }

  /**
   * Log user out by clearing cookie and redirecting
   */
  logout() {
    deleteSession();
    this.props.history.push(`/`);
  }

  render() {
    const { categories } = this.state;
    // if (this.state.categories === null) {
    //   return <div>{<Loading className={styles.containerLoading} />}</div>;
    // } else {
    //   console.log(categories);
      return (
        <div className={`${styles.container} animateFadeIn`}>
          <div className={styles.containerInner}>
            {/* Navigation */}

            <div className={styles.navigationContainer}>
              <div className={`link`}>
                {this.state.session ? this.state.session.userEmail : ""}
              </div>
              <div className={`link`} onClick={this.logout}>
                logout
              </div>
            </div>
            {/* Content */}

            <div className={`${styles.contentContainer}`}>
              <p>Quickplay - Choose a category</p>
              {this.state.loading && (
              <div>{<Loading className={styles.containerLoading} />}</div>
            )}
            {this.state && this.state.categories && !this.state.loading && (

              categories.map((category, i) => (
                <Link to={`/quickplay/category/${category}`}>
                <p key={i}>{category}</p>
                </Link>
              ))
              
            )}
            </div>
          </div>
        </div>
      );
    }
  }

export default withRouter(Quickplay);
