import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styles from "./Dashboard.module.css";
import { getSession, deleteSession } from "../../utils";

const CARD_DATA = [
  {
    title: "sports",
    icon: "fas fa-font",
    details: "Great sports quiz",
    color: "#e75d5d",
  },
  {
    title: "Design",
    icon: "fas fa-magic",
    details: "Coolest design quiz in the whole world",
    color: "#6fc0d1",
  },
  {
    title: "Geography",
    icon: "far fa-user",
    details: "Take you round the world and back",
    color: "#7dc691",
  },
  {
    title: "Computer Science",
    icon: "far fa-hand-paper",
    details: "From AI to bits and bobs",
    color: "#dfbd42",
  },
  {
    title: "Pop",
    icon: "fas fa-wrench",
    details: "Love and songs",
    color: "#d98e64",
  },
  {
    title: "News",
    icon: "far fa-edit",
    details: "Quiz with the latest news",
    color: "#de6bae",
  },
  {
    title: "News",
    icon: "far fa-edit",
    details: "Quiz with the latest news",
    color: "#de6bae",
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    const userSession = getSession();

    this.setState({
      session: userSession,
    });
  }

  /**
   * Log user out by clearing cookie and redirecting
   */
  logout() {
    deleteSession();
    this.props.history.push(`/`);
  }

  render() {
    return (
      <div className={`${styles.container} animateFadeIn`}>
        <div className={styles.containerInner}>
          {/* Navigation */}

          <div className={styles.navigationContainer}>
            <div className={`link`}>
              {this.state.session ? this.state.session.userEmail : ""}
            </div>
            <div className={`link`} onClick={this.logout}>
              logout
            </div>
          </div>

          <div className={`${styles.contentContainer}`}>
            <div className={`${styles.heroArtwork} animateFlicker`}>
              <img
                draggable="false"
                src={"./fullstack-app-artwork.png"}
                alt="serverless-fullstack-application"
              />
            </div>
            <div className={`${styles.containerCta}`}>
              <Link to="/quickplay">
                <button className={`${styles.buttonPlayMode}`}>
                  Quick Play
                </button>
              </Link>
              <Link to="/versus">
                <button className={`${styles.buttonPlayMode}`} disabled={true}>
                  Versus
                </button>
              </Link>
            </div>
            {/* 2nd row of buttons for more playmodes */}
            {/* <div className={`${styles.containerCta}`}>
              <Link to="/quickplay">
                <button className={`${styles.buttonPlayMode}`}>
                  Quick Play
                </button>
              </Link>
              <Link to="/versus">
                <button className={`${styles.buttonPlayMode}`} disabled={true}>
                  Versus
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
