/**
 * Utils: Back-end
 */

/**
 * Register a new user
 */
export const userRegister = async (email, password) => {
  return await requestApi("/users/register", "POST", { email, password });
};

/**
 * Login a new user
 */
export const userLogin = async (email, password) => {
  return await requestApi("/users/login", "POST", { email, password });
};

/**
 * userGet
 */
export const userGet = async (token) => {
  return await requestApi("/user", "POST", null, {
    Authorization: `Bearer ${token}`,
  });
};

/**
 * getCategories
 */
export const getCategories = async (token) => {
  return await requestApi("/question/categories", "GET", null, {
    Authorization: `Bearer ${token}`,
  });
};

/**
 * getCategories
 */
export const getQuestionsForCategory = async (token, category) => {
  return await requestApi(`/question?category=${category}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
};

/**
 * API request to call the backend
 */
export const requestApi = async (
  path = "",
  method = "GET",
  data = null,
  headers = {}
) => {
  // Prepare URL
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  var url = `${window.serverless.urls.api}${path}`;

  if (url.includes("cloudfront.net")) {
    url = `https://api-dev.quizbrawl.com` + `${path}`;
  }

  // Set headers
  headers = Object.assign({ "Content-Type": "application/json" }, headers);

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: "cors",
    cache: "no-cache",
    headers,
    body: data ? JSON.stringify(data) : null,
  });

  if (response.status < 200 || response.status >= 300) {
    const error = await response.json();
    throw new Error(error.error);
  }

  return await response.json();
};
